import React, { useState, useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { useTheme } from 'styled-components'

import imgTickets from '../assets/img-tickets.webp'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { PageLayout } from '../styles/Layout'
import { LegalsStyled } from '../styles/LegalsStyled'
import { TitleS, BodyM } from '../styles/Typography'

const PrivacyPolicy = () => {
  const theme = useTheme()

  const [isReverse, setIsReverse] = useState(false)

  const changeBackgroundColor = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const pageHeight = document.body.offsetHeight - (window.innerHeight + 300)

    if (windowScroll >= pageHeight) {
      setIsReverse(true)
    } else {
      setIsReverse(false)
    }
  }

  const onScroll = () => {
    window.requestAnimationFrame(changeBackgroundColor)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <SEO
        title="Butik | Privacy Policy"
        description="Butik Electronic Music Festival"
        image={imgTickets}
      />
      <PageLayout
        backgroundColor={
          isReverse ? theme.backgroundDark : theme.backgroundPrimary
        }
      >
        <Header
          textColor={isReverse ? theme.backgroundPrimary : theme.backgroundDark}
          logoColor={isReverse ? theme.backgroundPrimary : theme.backgroundDark}
        />
        <LegalsStyled>
          <div className="content">
            <div className="inner">
              <TitleS className="title">Privacy Policy</TitleS>
              <BodyM className="subtitle" weight={600}>
                Organizer of the Butik Festival
              </BodyM>
              <BodyM className="text">
                The organizer of the Butik Festival is Butik ENT d.o.o., located
                at Žabče 24, 5220 Tolmin, Slovenia. VAT number: SI31776531.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Data Protection Responsibility
              </BodyM>
              <BodyM className="text">
                In terms of data protection regulations, Butik ENT d.o.o. is
                responsible for your data. References to “we”, “our”, or “us” in
                this privacy policy refer to Butik ENT d.o.o.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Introduction
              </BodyM>
              <BodyM className="text">
                The Butik Festival is an electronic music festival held in
                Slovenia.
              </BodyM>
              <BodyM className="text">
                To ensure smooth operations, we need to process various data
                from our guests. We want to inform you about this and how you
                can contact us with any questions or concerns in this privacy
                policy. Please note that we generally assume that if you provide
                us with information or use our services, you have read and
                agreed to this privacy policy.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                What information do we collect about our guests?
              </BodyM>
              <BodyM className="text">
                We primarily collect and process the data necessary to ensure
                your smooth participation in the festival. This mainly includes
                the information you provide during the ticket purchase.
                Specifically, these are the following data:
              </BodyM>
              <ul>
                <li>Email address</li>
                <li>First and last name</li>
                <li>Residential address (country, city, street, number)</li>
                <li>Nationality</li>
                <li>Phone number</li>
                <li>Date of birth</li>
              </ul>
              <BodyM className="text">
                Typically, this data is sufficient to manage your participation.
                If additional data is required in individual cases, we will
                coordinate this with you directly.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                What information do we collect during your visit?
              </BodyM>
              <BodyM className="text">
                Generally, no personal data is collected from visitors during
                their participation in the Butik Festival. However, you may
                appear in photos and videos published on our website or other
                online channels. Please note that it is essential for us to
                report on the festival, and this requires publishing photos and
                videos. Also, remember that you agreed to the use of images when
                booking.
              </BodyM>
              <BodyM className="text">
                If you find photos on our website or other online channels that
                you are not happy with, please let us know at{' '}
                <a href="mailto:info@butikfestival.com">
                  info@butikfestival.com
                </a>
                . We will promptly address the issue.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                What information do we collect about the artists?
              </BodyM>
              <BodyM className="text">
                We collect and process only the data necessary for the
                organization and billing of the artists at the Butik Festival.
                This primarily includes names, addresses, bank details, and
                possibly VAT numbers.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                What information do we collect about the employees and other
                festival helpers?
              </BodyM>
              <BodyM className="text">
                We only collect the data necessary for managing our contractual
                relationship with all our helpers and employees, who make the
                festival possible, and for complying with our legal obligations
                as an employer. This mainly includes names, addresses, dates of
                birth, VAT numbers, social security numbers, and bank details.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                What other information do we collect?
              </BodyM>
              <BodyM className="text">
                We store any email correspondence with you. This is not only
                necessary for communication but also to trace why we did
                something for you later.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Cookies and analysis tools
              </BodyM>
              <BodyM className="text">
                Read more about our cookies policy{' '}
                <a onClick={() => scrollTo('#cookie-policy')}>here</a>.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Source of data
              </BodyM>
              <BodyM className="text">
                In the event that we did not collect your data ourselves or you
                did not provide it to us, it comes from a reliable source that
                we have verified as authorized to collect and transmit the data.
                In some cases, your data may come from a publicly accessible
                database. You have the right to request information and can ask
                us at any time where we obtained your data.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                How do we use your data?
              </BodyM>
              <BodyM className="text">
                As mentioned, we primarily use your data to manage your festival
                participation and to communicate with you. This includes, for
                example:
              </BodyM>
              <ul>
                <li>
                  Informing you about the festival you bought the ticket for and
                  sending you details about it.
                </li>
                <li>
                  Responding to your inquiries and fulfilling your requests,
                  such as sending you newsletters, product information,
                  information notices, or brochures.
                </li>
                <li>Gathering feedback on our products and services.</li>
                <li>
                  Sending you administrative information, such as changes to our
                  terms and conditions and policies.
                </li>
                <li>
                  Facilitating social media sharing and communication
                  functionalities.
                </li>
                <li>
                  Sending you information about our offers and future Butik
                  Festivals and similar events.
                </li>
              </ul>
              <BodyM className="subtitle" weight={600}>
                Legal Basis
              </BodyM>
              <BodyM className="text">
                Your data is processed by us based on one or more of the
                following legal grounds. Not all of these may apply to you
                personally.
              </BodyM>
              <ul>
                <li>
                  Because it is necessary to fulfill your booking or visit to
                  the Butik Festival, i.e., to fulfill the contract between us
                  or to prepare your participation, which means to take
                  pre-contractual measures.
                </li>
                <li>
                  Because it is necessary to fulfill legal obligations to which
                  we are subject. This particularly includes tax retention
                  obligations.
                </li>
                <li>
                  Because processing your data is necessary to protect our
                  legitimate interests or the legitimate interests of our
                  affiliated companies, cooperation partners, or sponsors.
                  Legitimate interest also includes conducting market
                  communication and advertising.
                </li>
                <li>
                  Because it is necessary to achieve our legitimate interest in
                  connection with the administration, operation, and
                  optimization of the website.
                </li>
                <li>
                  Because it is necessary to achieve our legitimate interest in
                  connection with quality control and business planning.
                </li>
                <li>
                  Because it is necessary to achieve our legitimate interest in
                  connection with conducting contests, raffles, and promotions
                  that you voluntarily participated in.
                </li>
                <li>
                  Because we need it to enforce our legal claims (e.g.,
                  collection) or handle insurance claims.
                </li>
                <li>
                  Because we need the data from video surveillance to protect
                  life and property.
                </li>
              </ul>
              <BodyM className="subtitle" weight={600}>
                With whom do we share your data?
              </BodyM>
              <BodyM className="text">
                We also share your data with third parties to ensure that you
                can participate in the Butik Festival or to fulfill our contract
                with you. This mainly includes:
              </BodyM>
              <ul>
                <li>
                  Other companies, associations, or individuals who organize or
                  host the Butik Festival.
                </li>
                <li>Our employees and contractors.</li>
                <li>
                  Our cooperation partners, sponsors, and service providers,
                  including but not limited to:
                  <ul>
                    <li>Operators of ticket sales platforms.</li>
                    <li>Booking platforms.</li>
                    <li>
                      Providers of online advertising and marketing services.
                    </li>
                    <li>
                      Providers of accommodation and other tourist services.
                    </li>
                    <li>Providers of payment services.</li>
                    <li>Banks.</li>
                    <li>Insurance companies.</li>
                    <li>Logistics companies.</li>
                    <li>
                      Legal representatives, public authorities, tax
                      consultants, auditors, and courts, if we need to involve
                      them or if we are legally obliged to do so.
                    </li>
                    <li>
                      Media partners and their journalists, who ensure the
                      promotion of our festival.
                    </li>
                    <li className="no-margin">
                      Companies involved in the maintenance and operation of the
                      website, including IT service providers and hosting
                      companies.
                    </li>
                  </ul>
                </li>
                <li>
                  Public authorities and courts when we are legally obliged to
                  do so, or it is necessary to protect our legal interests.
                </li>
              </ul>
              <BodyM className="subtitle" weight={600}>
                Duration of data storage
              </BodyM>
              <BodyM className="text">
                We store your data only as long as necessary to fulfill the
                purposes for which it was collected or as required by law.
                Specifically:
              </BodyM>
              <ul>
                <li>
                  Booking data will generally be deleted three years after the
                  last completed booking, provided there are no legal retention
                  obligations.
                </li>
                <li>
                  Correspondence related to booking or customer service will
                  generally be deleted three years after the last email,
                  provided there are no legal retention obligations.
                </li>
                <li>
                  Photos and videos will generally be deleted ten years after
                  the end of the festival.
                </li>
              </ul>
              <BodyM className="text">
                Please note that in individual cases, longer retention periods
                may be required due to legal obligations or legal defense.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Your rights
              </BodyM>
              <BodyM className="text">
                If you are in the European Economic Area (EEA), you have several
                rights concerning your data:
              </BodyM>
              <ul>
                <li>The right to access the data stored about you.</li>
                <li>
                  The right to rectify or update incorrect or incomplete data.
                </li>
                <li>
                  The right to delete data, especially if it is no longer
                  necessary for the purposes for which it was collected.
                </li>
                <li>
                  The right to object to the processing of your data,
                  particularly when it is based on our legitimate interest.
                </li>
                <li>
                  The right to data portability, i.e., to receive your data in a
                  structured, commonly used, and machine-readable format or to
                  transfer it directly to another responsible party.
                </li>
                <li>
                  The right to withdraw your consent to data processing at any
                  time.
                </li>
              </ul>
              <BodyM className="text">
                Please note that the rights listed above may be limited under
                applicable law.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Data security
              </BodyM>
              <BodyM className="text">
                We take appropriate technical and organizational measures to
                protect your data from unauthorized access, misuse, loss, and
                destruction. This includes firewalls, encryption, access
                restrictions, and monitoring of our systems. However, please
                note that we cannot guarantee the absolute security of your
                data.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                International data transfer
              </BodyM>
              <BodyM className="text">
                Your data may be transferred and processed in countries outside
                the European Economic Area (EEA), where the data protection laws
                may differ from those of your home country. In such cases, we
                ensure that appropriate safeguards are in place to protect your
                data, such as EU standard contractual clauses.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Contact us
              </BodyM>
              <BodyM className="text">
                If you have any questions about this privacy policy or how we
                handle your data, please contact us at{' '}
                <a href="mailto:info@butikfestival.com">
                  info@butikfestival.com
                </a>
                .
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                Changes to this privacy policy
              </BodyM>
              <BodyM className="text">
                We reserve the right to update this privacy policy from time to
                time to reflect changes in our practices or legal obligations.
                The updated version will be posted on our website, and we will
                notify you of significant changes.
              </BodyM>
              <BodyM className="text">Last updated: January 2024</BodyM>
              <div className="divider" />
              <TitleS className="title" id="cookie-policy">
                Cookie Policy
              </TitleS>
              <BodyM className="text">
                Butik ENT d.o.o. wants to offer its website visitors
                (www.butikfestival.com) the information that is relevant and
                important to them. By knowing what our website visitors are
                looking at on our website and what interests them we can offer
                them the right content. We never transfer the data that we
                collect on our website to third parties. Also, we collect the
                data completely anonymously, which means that we do not monitor
                our website visitors individually, but in an aggregated form of
                data.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                What are cookies and why are they necessary?
              </BodyM>
              <BodyM className="text">
                Cookies are small text files that most of the websites save to
                the device with which the user accesses the internet. This way
                the users are recognized by the website. Saving cookies is fully
                under control of the user, because they can limit or disable the
                use or storing of cookies.
              </BodyM>
              <BodyM className="text">
                Cookies are used by most of the websites because they enable the
                website owners to offer fresh and appropriate content which
                matches user's interests and preferences. Cookies also enable
                collecting the statistical data about website visits, therefore
                enabling the website owners to measure efficiency of their
                website, content and advertisements that they offer.
              </BodyM>
              <BodyM className="subtitle" weight={600}>
                List of cookies
              </BodyM>
              <div className="table-container">
                <table>
                  <tr>
                    <th>Cookie</th>
                    <th>Description</th>
                    <th>Duration</th>
                    <th>Type</th>
                  </tr>
                  <tr>
                    <td>_ga_*</td>
                    <td>
                      Google Analytics sets this cookie to store and count page
                      views.
                    </td>
                    <td>1 year 1 month 4 days</td>
                    <td>Analytics</td>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>
                      The _ga cookie, installed by Google Analytics, calculates
                      visitor, session and campaign data and also keeps track of
                      site usage for the site's analytics report. The cookie
                      stores information anonymously and assigns a randomly
                      generated number to recognize unique visitors.
                    </td>
                    <td>1 year 1 month 4 days</td>
                    <td>Analytics</td>
                  </tr>
                  <tr>
                    <td>_fbp</td>
                    <td>
                      Facebook sets this cookie to store and track interactions.
                    </td>
                    <td>3 months</td>
                    <td>Analytics</td>
                  </tr>
                  <tr>
                    <td>lastExternalReferrerTime</td>
                    <td></td>
                    <td>never</td>
                    <td>Other</td>
                  </tr>
                  <tr>
                    <td>{`@@scroll|/|initial`}</td>
                    <td></td>
                    <td>session</td>
                    <td>Other</td>
                  </tr>
                </table>
              </div>
              <BodyM className="subtitle" weight={600}>
                Disabling cookies
              </BodyM>
              <BodyM className="text">
                Most of the websites automatically accept cookies. You can
                always disable the cookies in the browser's settings. On the
                following links you can access the cookie settings for different
                browsers:
              </BodyM>
              <ul>
                <li>
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=en-en&p=cpn_cookies"
                    target="_blank"
                  >
                    Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="http://support.mozilla.org/sl/kb/Kako%20aktivirate%20in%20deaktivirate%20pi%C5%A1kote"
                    target="_blank"
                  >
                    Firefox
                  </a>
                </li>
                <li>
                  <a
                    href="http://windows.microsoft.com/sl-si/windows-vista/block-or-allow-cookies"
                    target="_blank"
                  >
                    Internet Explorer
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.opera.com/help/tutorials/security/privacy"
                    target="_blank"
                  >
                    Opera
                  </a>
                </li>
                <li>
                  <a href="http://www.apple.com/support/safari" target="_blank">
                    Safari
                  </a>
                </li>
              </ul>
              <div className="divider" />
              <TitleS className="title" id="ticket-terms-conditions">
                Ticket Terms & Conditions
              </TitleS>
              <BodyM className="text">
                Tickets for the Butik Festival are issued on behalf of the
                Organization (Butik ENT d.o.o.) responsible for organizing the
                event and are subject to the following terms and conditions:
              </BodyM>
              <BodyM className="text">
                The event, or parts of it, may be recorded live for use in sound
                recordings, video recordings, and other forms of audio and
                audiovisual reproductions. By purchasing this ticket, the holder
                agrees to participate in such recordings and grants all
                necessary consents as required under relevant laws.
              </BodyM>
              <BodyM className="text">
                Once purchased, tickets cannot be exchanged or refunded
                (statutory rights not affected). The promoters, performers,
                sponsors, venue, and ticket outlets are not responsible for any
                loss or damage to property, except where such loss or damage
                arises from a breach of contract or negligence on their part.
              </BodyM>
              <BodyM className="text">
                Upon entry to the festival grounds, tickets will be exchanged
                for a festival wristband, allowing for free re-entry. Once
                exchanged, the ticket is no longer valid. The ticket holder
                acknowledges and accepts the rules posted on the festival
                website. Failure to adhere to these rules may result in removal
                from the venue and potential legal action.
              </BodyM>
              <BodyM className="text">
                Butik ENT d.o.o. reserves the right to make changes to the
                artist lineup in line with the event's concept. In the event of
                such changes, tickets remain valid and non-refundable. All
                updates will be announced on the official website and social
                media, so attendees are encouraged to check these regularly.
              </BodyM>
              <BodyM className="text">
                The festival ticket includes camping and is only valid for the
                specified dates. Additional services, such as Early Arrival or
                parking, are not included and may incur extra charges, payable
                onsite or online.
              </BodyM>
              <BodyM className="text">
                In case of event cancellation, tickets can be returned to the
                point of purchase within 30 days, according to the shop's return
                policy. Additional expenses incurred due to changes or
                cancellation of the event, such as travel or accommodation
                costs, will not be reimbursed.
              </BodyM>
              <BodyM className="text">
                Butik ENT d.o.o. reserves the right to refuse admission or
                remove individuals from the grounds for reasons of public
                safety, overcrowding, or other concerns. In such cases, the
                ticket holder's sole remedy is a refund of the ticket's face
                value.
              </BodyM>
              <BodyM className="text">
                Ticket holders are responsible for their tickets after purchase;
                the organization is not liable for lost or damaged tickets.
                Admission is by full ticket only, with no re-admission unless
                previously arranged.
              </BodyM>
              <BodyM className="text">
                Tickets should be purchased from authorized outlets only. Those
                attempting to gain entry with counterfeit tickets will be
                prosecuted.
              </BodyM>
              <BodyM className="text">
                The ticket holder assumes all risks of injury and responsibility
                for property loss or theft, releasing the promoters, performers,
                sponsors, venue, and ticket outlets from liability, except for
                cases of personal injury or death caused by negligence or
                fraudulent acts.
              </BodyM>
              <BodyM className="text">
                All attendees will be subject to a search upon entry, as
                permitted by law. Refusal to consent to a search may result in
                denial of entry.
              </BodyM>
              <BodyM className="text">
                Recording a performer's performance without prior written
                consent is a criminal offense. The use of professional cameras
                or recording equipment without the Organizer's approval is
                prohibited.
              </BodyM>
              <BodyM className="text">
                The event will proceed regardless of weather conditions. Tickets
                cannot be used for promotional purposes unless authorized by the
                Organizer, and they may not be resold for more than their face
                value.
              </BodyM>
            </div>
            <Footer isReverse={isReverse} />
          </div>
        </LegalsStyled>
      </PageLayout>
    </>
  )
}

export default PrivacyPolicy
